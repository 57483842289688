<template>
  <div class="carousel">
    <teleport to="#css" type="text/css">
      body { font-size:{{ fontSize / 70 }}vh; font-size:{{ fontSize / 70 }}vmin;
      font-size:calc({{ fontSize / 140 }}vh + {{ fontSize / 140 }}vw); }
    </teleport>
    <div
      v-if="showCounter"
      style="
        position: fixed;
        right: 3vw;
        top: calc(3vh - 3px);
        font-size: 1rem;
        width: 3rem;
        z-index: -1;
      "
    >
      <Counter :timer="timer" :value="counter" />
    </div>
    <div
      v-for="(screen, index) in screens"
      :key="'screen' + screen.id"
      class="slide"
      v-show="index == counter"
    >
      <Slide :value="screen" />
    </div>
    <div
      v-for="(page, index) in pages"
      :key="'page' + page.id"
      class="slide"
      v-show="index == counter"
    >
      <Page :value="page" />
    </div>
    <video
      v-if="video && counter == length - 1"
      width="100%"
      autoplay
      muted
      style="z-index: 1"
      loop
    >
      <source :src="video" type="video/mp4" />
    </video>
    <div v-if="!hasScreens && !hasPages" class="slide">
      Momentan leider kein Inhalt verfügbar!
    </div>
    <Points
      class="points"
      :value="counter"
      :count="length"
      @clicked="clicked"
    />
  </div>
</template>

<script>
import Counter from "./Counter.vue";
import Page from "./Page.vue";
import Points from "./Points.vue";
import Slide from "./Slide.vue";

export default {
  name: "CarouselComp",
  components: { Counter, Page, Points, Slide },
  data() {
    return {
      counter: 0,
      pages: [],
      running: false,
      screens: [],
      timeout: null,
      timer: 15,
    };
  },
  computed: {
    video() {
      const d = new Date();
      // only in december
      if (d.getMonth() != 11) {
        return null;
      }
      // Samstag
      if (d.getDay() == 6) {
        return d.getDate() - 1 + ".mp4";
      }
      // Sonntag
      if (d.getDay() == 0) {
        return d.getDate() - 2 + ".mp4";
      }
      return d.getDate() + ".mp4";
    },
    server() {
      return process.env.VUE_APP_SERVER;
    },
    hasScreens() {
      return this.screens.length > 0;
    },
    hasPages() {
      return this.pages.length > 0;
    },
    hasItems() {
      return this.items.length > 0;
    },
    showCounter() {
      return this.length > 1 && this.timeout;
    },
    items() {
      return this.screens.length ? this.screens : this.pages;
    },
    length() {
      return this.video ? this.items.length + 1 : this.items.length;
    },
    fontSize() {
      if (this.screens.length > 0) {
        if (!this.screens[this.counter]) {
          return 200;
        }
        return this.screens[this.counter].fontSize
          ? this.screens[this.counter].fontSize
          : 200;
      }
      return 200;
    },
  },
  methods: {
    clicked(index) {
      if (this.counter == index) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        } else {
          this.nextElement();
        }
      } else {
        this.counter = index;
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
      }
    },
    nextElement() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        // if no screens, then check always
        if (!this.hasScreens) {
          await this.fetchScreens();
          if (this.hasScreens) {
            this.counter = 0;
          }
        }
        // refresh at end of cycle
        if (this.items.length == 0 || this.counter == this.length - 1) {
          if (this.hasScreens) {
            await this.fetchData();
          } else {
            await this.fetchPages();
          }
          this.counter = 0;
        } else {
          this.counter = (this.counter + 1) % this.length;
        }
        this.nextElement();
      }, this.timer * 1000);
    },
    async fetchData() {
      await this.fetchScreens();
      if (!this.hasScreens) {
        await this.fetchPages();
      } else {
        this.pages = [];
      }
    },
    async fetchPages() {
      this.pages = (
        await (
          await fetch("https://www.gymkirchenfeld.ch/aktuellesjson/")
        ).json()
      ).result;
    },
    async fetchScreens() {
      this.screens = (
        await (
          await fetch(
            `https://${this.server}.gymkirchenfeld.ch/api/screen/screen`
          )
        ).json()
      ).result;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.nextElement();
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  height: 100%;
}
.slide {
  margin: auto;
}
.points {
  position: fixed;
  bottom: 3vh;
  width: 100%;
}
</style>
